.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* IconComponent.css */

.icon-container {
    text-align: center;
    margin: 1%;
    padding: 1%
}

.rotate-container {
    margin: auto;
}

.rotating-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 0.1px solid #000000;
    padding: auto;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, scale 0.3s ease-in-out;
}

.rotating-icon:hover {
    transform: scale(1.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
    /* Adjust shadow properties as needed */
}

.icon-title {
    font-size: 18px;
    margin-top: 5px;
    font-weight: bold;
    padding: 5%;
}

.card-with-shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.card-with-shadow:hover {
    transform: scale(1.10);
    box-shadow: 20px 18px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.card-img-top {
    min-height: 270px;
}


/* Set a specific z-index for your modal */

.lightbox {
    z-index: 1;
}

.lightbox lightbox-visible {
    z-index: 1003 !important;
}

.modal-dialog {
    max-width: 75% !important;
    margin: auto !important;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: xx-large;
}


/* Set a higher z-index for the lightbox */

.react-responsive-modal-modal {
    z-index: 1100 !important;
    /* Make sure this value is higher than the modal's z-index */
}


/* Custom styles for gallery images */

.ReactGridGallery_tile {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.ReactGridGallery_tile:hover {
    transform: scale(1.3);
    z-index: 1;
    box-shadow: 0 0 10px rgb(0, 0, 0);
}

.timeline-icon-1 {
    background-color: white;
}